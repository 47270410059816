import { NavLink } from "react-router-dom";
import "./Header.css"

export const Header = () => {
  return (
    <header>
        <nav>
          <ul className="nav-list">
            <li className="nav-list-logo"><NavLink to="/">Annexvägen</NavLink></li>
            {/* <li className="nav-list-link"><NavLink to="/press">Press</NavLink></li> */}
          </ul>
        </nav>
      </header>
  );
}

