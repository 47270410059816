import React from 'react';
import { Hero } from '../components/Hero/Hero';
import { Press } from '../pages/Press'

export const Home = () => {
  return (
   <>
    <Hero />
    <div className="container">    
      <Press />
   </div>
   </>
  );
}

export default Home;
