import './App.css';
import './assets/fonts/ManualExtraBoldCompressed/font.woff';
import { 
  createBrowserRouter, 
  createRoutesFromElements, 
  Route, 
  RouterProvider 
} from "react-router-dom";
import { Home } from "./pages/Home"
import { NotFound } from './pages/NotFound'
import { Layout } from './layout/Layout';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <Home />
      },
    ]
  }
])
  // createRoutesFromElements(
  //   <Route path="/" element={<Layout />}>
  //     <Route index path="/" element={<Home />} />
  //     <Route path="/press" element={<Press />} />
  //   </Route>
  // )
  //   createRoutesFromElements(
  //   {
  //     path: "/",
  //     element: <Layout />,
  //     children: [
  //       {
  //         path: "/",
  //         element: <Home />,
  //       },
  //       {
  //         path: "/press",
  //         element: <Press />
  //       }
  //     ]
  //   }
  // )


function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
