import React from 'react';
import "./Footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTiktok, faSpotify } from '@fortawesome/free-brands-svg-icons'

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="some-icons">
        <a href="https://www.facebook.com/profile.php?id=100063681624242&paipv=0&eav=AfbK9AzVhR0LeA80nJBq01VvoMK56WBSz4XtUJfX5jkbaAoDLj7eR_-NT7X1lkSy4zw" rel="noreferrer" target="_blank" alt="facebook">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a href="https://www.instagram.com/annexvagen/" rel="noreferrer" target="_blank" alt="instagram">
          <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a href="https://www.tiktok.com/@annexvagen" rel="noreferrer" target="_blank" alt="tikTok">
        <FontAwesomeIcon icon={faTiktok} />
      </a>
      <a href="https://open.spotify.com/artist/0bWZA57HyWUW3QYnwlBBcc?si=ovR3SMFNQkm4wx3DtiQobg" rel="noreferrer" target="_blank" alt="spotify">
        <FontAwesomeIcon icon={faSpotify} />
      </a>
      </div>
      <p className="copy-wrapper">Copyright Annexvägen {new Date().getFullYear()}</p>
    </footer>
  );
}

