import './NotFound.css'
import { NavLink } from 'react-router-dom'
import { Header } from '../components/Header/Header'
import { Footer } from '../components/Footer/Footer'


export const NotFound = () => {
  return (
    <>
      <Header />
        <div className="not-found-wrapper">
          <p>Sidan hittades inte <NavLink to="/">Tillbaka</NavLink></p>
        </div>
      <Footer />
    </>
  );
}