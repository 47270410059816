import './Press.css';
import Stockholmshjärta from '../../src/assets/images/stockholmshjärta-cover.webp'
import Annexvägen from '../../src/assets/images/annexvagen-promo.webp'


export const Press = () => {
  return (
    <div className="container">
      <section className="press">
        <h2 className='press-headline'>Stockholmshjärta ute nu</h2>
        <article>
            <p><span className="date-bold">14:E JANUARI 2023 (STOCKHOLM)</span> Annexvägen har släppt sin debutskiva "Stockholmshjärta". En skiva om staden och människorna, det fina och det fula och om längtan att bryta sig fri från omvärldens förväntningar och skapa sig ett liv utifrån sina egna drömmar.</p>
            <p>Skivan innehåller nio spår av melodiös rock med popsensibilitet och texter på svenska. Stilmässigt hämtas inspirationen från den Amerikansk Heartland-rocken (Bruce Springsteen, The Killers, The Gaslight Anthem) samt ur den svenska pop- och rocktraditionen (Kent, Håkan Hellström, Imperiet).</p>
            <p>Stockholmshjärta finns nu tillgänglig digitalt på alla musikstreamingtjänster.</p>
          <div className="press-image-wrapper">
            <img alt="stockholmshjärta" src={Stockholmshjärta} />
            <img alt="stockholmshjärta" src={Annexvägen} />
          </div>
          <h3 className="press-tracks">Låtlista</h3>
          <ul className="press-track-list">
            <li>1. Tro, hopp och känsla</li>
            <li>2. Stockholmshjärta</li>
            <li>3. Ett förlorat år</li>
            <li>4. Evig vinter</li>
            <li>5. Horisonten</li>
            <li>6. Tidens brända broar</li>
            <li>7. Vingar av glas</li>
            <li>8. SK945</li>
            <li>9. Speciell</li>
          </ul>
          <div className="press-spotify-iframe">
            <iframe title="spotify-iframe" style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/album/6DQjaiVLDHdplTgRb2XYf4?utm_source=generator" width="100%" height="352" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
          <div className="press-youtube-iframe"></div>
          <h3 className="press-about">Om Annexvägen</h3>
          <p>Annexvägen är Sveriges nya rockband. Deras stora refränger, träffsäkra melodispråk och lokalt förankrade texter skapar något nytt och unikt på den svenska musikscenen. Bandet bildades 2019 och består av bröderna Martin och Philip Lunnegård samt John Jönerholm. 2023 släppte de sin efterlängtade debutskiva "Stockholmshjärta".</p>
       </article>
      </section>
    </div>  
  );
}

