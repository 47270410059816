import React from 'react';
import './Hero.css'
import HeroIMG from '../../assets/images/hero-mobile.webp'

export const Hero = () => {
  return (
    <div className="hero" style={{
      backgroundImage: `url(${HeroIMG})`
    }}>
      <div className="hero-container">
      <h1 className="hero-container--headline">Annexvägen</h1>
      </div>
    </div>
  );
}

